import React, { Component } from 'react'

import CyclePage from '../../containers/CyclePage'
import PrimaryLayout from '../../components/layouts/PrimaryLayout'
import pagesInfos from '../../config/i18n/pagesInfos'

const CyclePrintemps2022 = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCyclesEtStagesCyclePrintemps2022}
    >
      <CyclePage cycle="printemps-2022" />
    </PrimaryLayout>
  )
}

export default CyclePrintemps2022
